import { Injectable } from '@angular/core';
import { StoreObject, TypePolicies, TypePolicy } from '@apollo/client';
import { DeepMerger } from '@apollo/client/utilities';

import { ILogger } from '@shure/shared/angular/utils/logging';

import { ApolloTypePolicyProvider } from '../services';

import { deepMergeReconciler } from './deep-merge-reconciler';

const mergePolicyNoKey: TypePolicy = {
	keyFields: false,
	merge: true
};

const deepMergePolicyNoKey: TypePolicy = {
	keyFields: false,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	merge: (e, i, { toReference }): any => {
		return new DeepMerger(deepMergeReconciler).merge(e, i, {
			toReference: toReference
		});
	}
};

const mergePolicyNoKeyKeepTypename = (logger: ILogger): TypePolicy => {
	return {
		keyFields: false,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		merge: (e, i, { toReference, isReference, readField }): any => {
			const typename = isReference(e)
				? readField({
						fieldName: '__typename',
						from: e
				  })
				: e?.__typename;

			if (!typename) {
				logger.error('mergePolicyNoKeyKeepTypename', 'could not resolve __typename to keep from existing', {
					e,
					i
				});
				return e;
			}

			// copy incoming data as a workaround for __typename being readonly
			// Apollo will not merge with existing if the __typename fields don't match
			// eslint-disable-next-line @typescript-eslint/naming-convention
			const copy = { ...i, __typename: typename };

			return new DeepMerger(deepMergeReconciler).merge(e, copy, {
				toReference: toReference
			});
		}
	};
};

const mergePolicyFixedTypename = (typename: string): TypePolicy => {
	return {
		merge: true,
		fields: {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			__typename: {
				// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
				merge(_e, _i) {
					return typename;
				}
			}
		}
	};
};

const mergePolicyUseNewValue: TypePolicy = {
	merge: (_e, i) => {
		return i;
	}
};

@Injectable({
	providedIn: 'root'
})
export class SysApiTypePolicyProvider implements ApolloTypePolicyProvider {
	private readonly logger: ILogger;

	constructor(logger: ILogger) {
		this.logger = logger.createScopedLogger('SysApiTypePolicyProvider');
	}

	public typenameMap(): { [__typename: string]: (storeObject: Readonly<StoreObject>) => string } {
		// This mapping will map 'XXChange' event from the nodeChanges-subscription into the XX entry in the apollo cache
		// this only works because the type of 'XXChange' is a subset of the type 'XX'.
		return {
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			DeviceChange: (storeObject) => `Device:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			DeviceStatusChange: (storeObject) => `Device:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			RoomChange: (storeObject) => `Room:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			AudioChannelChange: (storeObject) => `AudioChannel:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			RfChannelChange: (storeObject) => `RfChannel:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			UserPresetChange: (storeObject) => `UserPreset:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			EqualizerFilterChange: (storeObject) => `EqualizerFilter:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			AudioCoverageAreaChange: (storeObject) => `AudioCoverageArea:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			CrossPointChange: (storeObject) => `CrossPoint:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			ChargerBayChange: (storeObject) => `ChargerBay:${storeObject['id']}`
		};
	}

	public typePolicies(): TypePolicies {
		return {
			/*
			 * Room
			 */
			RoomChange: mergePolicyFixedTypename('Room'),
			RoomManifestDevice: mergePolicyNoKey,

			/*
			 * Device
			 */
			// First level in device which is not normalized (no id), should deep merge
			DeviceDescription: deepMergePolicyNoKey,
			// First level in device which is not normalized (no id), should deep merge
			DeviceFeatures: deepMergePolicyNoKey,
			// First level in device which is not normalized (no id), should deep merge
			InterfaceDetails: deepMergePolicyNoKey,

			DeviceAdded: mergePolicyNoKey,
			DeviceRemoved: mergePolicyNoKey,

			// Types are mapped to Device in cache
			DeviceChange: mergePolicyFixedTypename('Device'),
			DeviceStatusChange: mergePolicyFixedTypename('Device'),

			DeviceAllEqualizersBypassChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAllIntellimixBypassChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceDanteAudioEncryptionChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAuthenticationChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAutomixGainMeteringChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAvailablePackagesChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceCallStatusChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceDanteAudioNetworkChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceDanteAudioNetworkAccessChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceEqualizerContourChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceEthernetLightsChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceIdentifyChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceInternetOfThingsChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceMeteringModesChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAudioMuteChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceVirtualAcousticBoundaryChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLinkedAccessPointChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceNameChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceUpdateProgressChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceUptimeChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceUserPresetsChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceCoveragePositionChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceRoutingPositionChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLocalLicenseChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceServerLicenseChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLicenseChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLicenseChannelCountChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceCoverageModeChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceRotationChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceHeightChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAudioMuteControlGroupChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceRoomChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingBrightnessChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLoudspeakerChange: mergePolicyNoKeyKeepTypename(this.logger),
			DevicePlacementChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceActivePresetChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAudioMuteControlChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAudioMuteDefaultChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceBatteryHealthChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceBatteryLevelChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceBatteryStorageModeChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceCommandStringsServiceChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceConfigurationLockChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceDoubleStuffProxiedTransmittersChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceGlobalMicStatusChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceWebServiceChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingAutomixGatingChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingBehaviorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingLiveMetersChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingMuteBehaviorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingRingChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingUnmuteBehaviorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceListenerHeightChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLogicMuteChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLogicReceiverChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLogicTransmitterChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceMdnsServiceChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceMicStatusChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceSecurity8021XChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceStereoAudioChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceSwitchConfigurationChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceUsbMuteSyncChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceUsbTerminalTypeChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceDeploymentCandidatesChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceProxiedDevicesChange: mergePolicyNoKeyKeepTypename(this.logger),
			ConnectedDeviceLabelChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAudioChannelCountChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAudioChannelsChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAudioMuteLockChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingAudioMetersChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingAudioMuteIndicatorOverrideChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingLiveAudioMetersChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAudioNetworkInterfaceChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAudioNetworkChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceControlNetworkInterfaceChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceControlNetworkChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAudioAutoFocusChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceFirmwareUpdatePolicyChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAnalogAudioOutputModeChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAnalogLogicChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAnalogLogicPresetSwitchChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceAnalogLogicPresetSwitchStatusChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLicenseChangeV2: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLicenseChangeV3: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceTransmitterOutOfRangeBehaviorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceConferencingOperationModeChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceRfPowerChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceTransmitterIdentifyBehaviorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceRedundantAudioNetworkChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceRfDensityModeChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceTransmitterLightingBehaviorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceTransmitterRejoinBehaviorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceTransmitterSwitchBehaviorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceDectRfSpectrumScanChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceSmartGateChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingSplitModeBreathingMeterColorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingSplitModeSolidMeterColorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceOnChargerChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingTouchPanelModeChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceTransmitterInitialStateFromChargerChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceTransmitterMuteBehaviorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceBodypackMicrophoneModeChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceErrorIndicatorChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLinkButtonLockChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceTagsChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceGatewayDeviceChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceConferencingOperationModeButtonLockChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceDnsClientConfigurationChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceLightingAudioActivityChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceBluetoothDeviceInformationServiceChange: mergePolicyNoKeyKeepTypename(this.logger),
			DeviceTemperatureChange: mergePolicyNoKeyKeepTypename(this.logger),

			/*
			 * StereoAudioChannel
			 */
			// First level in device which is not normalized (no id), should deep merge
			StereoAudioChannelDescription: deepMergePolicyNoKey,

			/*
			 * AudioChannel
			 */
			// First level in device which is not normalized (no id), should deep merge
			AudioChannelDescription: deepMergePolicyNoKey,
			// First level in device which is not normalized (no id), should deep merge
			AudioChannelFeatures: deepMergePolicyNoKey,

			// Types are mapped to Device in cache
			AudioChannelChange: mergePolicyFixedTypename('AudioChannel'),
			AudioChannelChannelConfigurationChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelSelectedLoudspeakerChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAcousticEchoCancellationChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAcousticEchoCancellationMonoReferenceChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAcousticEchoCancellationStereoReferenceChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAnalogLevelChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAdvancedAutomixerChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAecReferenceChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAutoMuteChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAutoPositionChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAnalogInputChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAutomixSourceSelectorChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAutomixerInputChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAutomixerVoiceActivityDetectionChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAnalogOutputChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAutoFocusChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAutoLevelChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAutomaticGainControlChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAutomixerChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelCompressorChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelConnectionDetectionChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelDelayChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelMonitoringChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelDirectOutputChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelDanteEncryptionStatusChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelFaderGroupChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelGainChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelGainLockChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelGateChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelIntellimixChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelLimiterChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelLobeChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelLogicChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelMixBusChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelMuteChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelMuteGroupChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelMuteLockChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelNameChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelNoiseReductionChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelPcInputChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelPcOutputChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelDanteRouteReceiverChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelDanteRouteTransmitterChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelSoloChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelSoloMixChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelThresholdLimiterChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelTalkerHeightChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelOutputPathChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelSignalGeneratorChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelDenoiserChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelDirectOutputTapPointChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelEqualizerChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelEqualizerContourChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelLobeConfigurationChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelLobePositionChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelLobeTalkerHeightChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelLobeWidthChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelLogicReceiverChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelMicOptimizationChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelMicPolarPatternChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelMicrophoneAngleChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelMonitorMixChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelMonitorMixReverbChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelPcPeripheralChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelPhantomPowerChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelPlosiveReductionChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelPostGateGainChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelPostGateMuteChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelReverbChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelDeviceSpecificEqualizerContourChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelEqualizerPresetChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelIdentifyChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelLightingBehaviorChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelStereoReverseChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelStereoWidthChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelPostGateSoloChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelDeverbChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelInputSourceChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelCarriedByRfChannelsChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelUsbOutputModeChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelStereoGainChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelPanChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelAnalogInputUseChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioChannelTransmitterClipChange: mergePolicyNoKeyKeepTypename(this.logger),

			/*
			 * RfChannel
			 */
			// First level in device which is not normalized (no id), should deep merge
			RfChannelDescription: deepMergePolicyNoKey,
			// First level in device which is not normalized (no id), should deep merge
			RfChannelFeatures: deepMergePolicyNoKey,

			RfChannelChange: mergePolicyFixedTypename('RfChannel'),
			RfChannelFeatureChange: mergePolicyNoKeyKeepTypename(this.logger),
			RfChannelLinkedTransmitterChange: mergePolicyNoKeyKeepTypename(this.logger),
			RfChannelInterferenceDetectionChange: mergePolicyNoKeyKeepTypename(this.logger),
			RfChannelInterferenceDetectorChange: mergePolicyNoKeyKeepTypename(this.logger),
			RfChannelTuningChange: mergePolicyNoKeyKeepTypename(this.logger),
			RfChannelMuteChange: mergePolicyNoKeyKeepTypename(this.logger),

			/*
			 * ChargerBay
			 */
			// First level in device which is not normalized (no id), should deep merge
			ChargerBayDescription: deepMergePolicyNoKey,
			// First level in device which is not normalized (no id), should deep merge
			ChargerBayFeatures: deepMergePolicyNoKey,

			ChargerBayChange: mergePolicyFixedTypename('ChargerBay'),
			ChargerBayFeatureChange: mergePolicyNoKeyKeepTypename(this.logger),
			ChargerBayDockedDeviceChange: mergePolicyNoKeyKeepTypename(this.logger),
			ChargerBayBatteryCapacityChange: mergePolicyNoKeyKeepTypename(this.logger),
			ChargerBayBatteryHealthChange: mergePolicyNoKeyKeepTypename(this.logger),
			ChargerSlotChange: mergePolicyNoKeyKeepTypename(this.logger),
			ChargerSlotFeatureChange: mergePolicyNoKeyKeepTypename(this.logger),
			ChargerSlotChargingSlotTypeIdChange: mergePolicyNoKeyKeepTypename(this.logger),

			/*
			 * EqualizerFilter
			 */
			// First level in device which is not normalized (no id), should deep merge
			EqualizerFilterDescription: deepMergePolicyNoKey,
			// First level in device which is not normalized (no id), should deep merge
			EqualizerFilterFeatures: deepMergePolicyNoKey,

			EqualizerFilterChange: mergePolicyFixedTypename('EqualizerFilter'),
			EqualizerFilterConfigurationChange: mergePolicyNoKeyKeepTypename(this.logger),
			EqualizerFilterBandwidthChange: mergePolicyNoKeyKeepTypename(this.logger),
			EqualizerFilterFrequencyChange: mergePolicyNoKeyKeepTypename(this.logger),
			EqualizerFilterGainChange: mergePolicyNoKeyKeepTypename(this.logger),
			EqualizerFilterQFactorChange: mergePolicyNoKeyKeepTypename(this.logger),

			/*
			 * UserPreset
			 */
			// First level in device which is not normalized (no id), should deep merge
			UserPresetDescription: deepMergePolicyNoKey,
			// First level in device which is not normalized (no id), should deep merge
			UserPresetFeatures: deepMergePolicyNoKey,

			UserPresetChange: mergePolicyFixedTypename('UserPreset'),

			/*
			 * AudioCoverageArea
			 */
			// First level in device which is not normalized (no id), should deep merge
			AudioCoverageAreaDescription: deepMergePolicyNoKey,
			// First level in device which is not normalized (no id), should deep merge
			AudioCoverageAreaFeatures: deepMergePolicyNoKey,

			AudioCoverageAreaChange: mergePolicyFixedTypename('AudioCoverageArea'),
			AudioCoverageAreaGainChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioCoverageAreaMuteChange: mergePolicyNoKeyKeepTypename(this.logger),
			AudioCoverageAreaConfigurationChange: mergePolicyNoKeyKeepTypename(this.logger),

			/*
			 * CrossPoint
			 */
			// First level in device which is not normalized (no id), should deep merge
			CrossPointDescription: deepMergePolicyNoKey,
			// First level in device which is not normalized (no id), should deep merge
			CrossPointFeatures: deepMergePolicyNoKey,

			CrossPointChange: mergePolicyFixedTypename('CrossPoint'),
			CrossPointConfigurationChange: mergePolicyNoKeyKeepTypename(this.logger),
			CrossPointGainChange: mergePolicyNoKeyKeepTypename(this.logger),

			/*
			 * Special rule to merge result or subscription/query in the cache
			 */
			Subscription: {
				fields: {
					discoveredDevices: mergePolicyUseNewValue,
					discoveredRooms: mergePolicyUseNewValue
				}
			},
			Query: {
				fields: {
					discoveredDevices: mergePolicyUseNewValue,
					discoveredRooms: mergePolicyUseNewValue
				}
			}
		};
	}
}
